import React from "react";
import YouTube, { YouTubeProps } from 'react-youtube';

const VideoPanel = ({type, curVideoID}) => { 

  const opts: YouTubeProps['opts'] = {
    position: 'flex',
    width: '100%',
    height: '100%',
    zIndex: '0',
    playerVars: {
      autoplay: 1,
      controls: 1,
      origin: window.location.origin,
    },
  };

  if(type==='Video'){
    return (
      <div className="videoContainer">
        <YouTube id='player' videoId={curVideoID} opts={opts} frameborder="0" />
      </div>
    )
  }
};

export default VideoPanel;