import React from "react";
import $ from 'jquery';
import ImagesSlider from '../components/ImagesSlider';

function Weddings (){
    return(  
      <div className="content" onLoad={()=>{
        $("#iDBtnWeddings")[0].style.textShadow = '5px 5px 5px #070707';
      }}>
      <div className="layoutBackground" />
        <ImagesSlider GroupIndex={2} />   
      </div>          
    )
}

export default Weddings;