import React from 'react';
import $ from 'jquery';
import { ImFacebook, ImPhone } from 'react-icons/im';
import Logo from '../assets/GeneGallery.png';
import NavBar from './NavBar';
import { Link } from 'react-router-dom';

/* import Logo from process.env.PUBLIC_URL+"/images/GeneGallery.png"; */

const Header = () => {
    return (
        <div className='header'>
            <div className='logo'>
                <Link to ='/Home' onClick={()=>{$(".menuBtn").css("textShadow","none")}}>
                    <img className='logoImg' src={Logo} alt="Gene Gallery"/>
                </Link>                              
            </div>
            <div className='media'>
                <div>
                    <Link to="https://www.facebook.com/GeneGallery2006/" target={'_blank'}><ImFacebook size="30px" /></Link>              
                </div>
                <div>
                    <Link to='/Contact' className='btn' onClick={()=>{
                        $(".menuBtn").css("textShadow","none")
                    }}>
                        <ImPhone size="30px" />
                    </Link>                                                 
                </div>                                             
            </div>            
            <div className='menubar'>
                <NavBar />
            </div>             
        </div>         
    );
};

export default Header;