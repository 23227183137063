import React, { useState} from 'react';
import { ImagesData } from '../assets/Data';
import VideoPanel from './VideoPanel';
import PhotoPanel from './PhotoPanel';
import $ from 'jquery';

const ImagesSlider=({GroupIndex})=>{
    const [curSubGroupIndex, setSubGroupIndex] = useState(0);    
    const groupArray = ImagesData.at(GroupIndex).subGroup;
    var type = ImagesData.at(GroupIndex).subGroup.at(curSubGroupIndex).type;
    var subGroupTotalArray = ImagesData.at(GroupIndex).subGroup.at(curSubGroupIndex).link.length - 1;
    var imgArray = ImagesData.at(GroupIndex).subGroup.at(curSubGroupIndex).link;    

    const [curVideoID, setVideoID] = useState(null);
    
    const goToGroup = (groupIndex) => {        
        setSubGroupIndex(groupIndex);
        type = ImagesData.at(GroupIndex).subGroup.at(groupIndex).type;
        $("#iDGroupDotsBtn" + groupIndex).css('padding-bottom', '10px');
        $("#iDGroupDotsBtn" + groupIndex).css('border-bottom', '2px solid #ff0505');        

        if (type==="Video"){            
            setVideoID(ImagesData.at(GroupIndex).subGroup.at(groupIndex).link.at(0).url); 
        }else{
            subGroupTotalArray = ImagesData.at(GroupIndex).subGroup.at(curSubGroupIndex).link.length - 1;
            imgArray = ImagesData.at(GroupIndex).subGroup.at(curSubGroupIndex).link;               
        }              
    }  

    return (
        <div className="wrapper1" >
            <div className='groupContainer'>
                <select className="groupSelectStyle" onChange={(val) => goToGroup(val.target.value)}>
                    {groupArray.map((groupID, groupIndex) => (
                        <option id={"iDGroupDotsBtn" + groupIndex} key={groupIndex} value={groupIndex}>{groupArray.at(groupIndex).title}</option>
                    ))}                    
                </select>
            </div>            
            <PhotoPanel GroupID={curSubGroupIndex} type={type} subGroupTotalArray={subGroupTotalArray} ImagesData={imgArray} />
            <VideoPanel type={type} curVideoID={curVideoID}/>
        </div>       
    )
}

export default ImagesSlider; 