const Footer = () => {
    const CurrentYear = new Date().getFullYear();
    return (
        <div className='footer'>
            <h5>Copyright &copy; {CurrentYear}, Gene Gallery.</h5>
        </div>      
    );
};

export default Footer;

