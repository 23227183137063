import React from "react";
import $ from 'jquery';
import ImagesSlider from '../components/ImagesSlider';

function Corporates (){
    return(
      <div className="content" onLoad={()=>{
        $("#iDBtnCorporates")[0].style.textShadow = '5px 5px 5px #070707';        
      }}>
      <div className="layoutBackground" />         
        <ImagesSlider GroupIndex={0} />        
      </div> 
    )
}

export default Corporates;