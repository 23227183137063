import React from "react";
import $ from 'jquery';
import ImagesSlider from '../components/ImagesSlider';

function Portraits (){
    return(  
      <div className="content" onLoad={()=>{
        $("#iDBtnPortraits")[0].style.textShadow = '5px 5px 5px #070707';
      }}>
      <div className="layoutBackground" />         
        <ImagesSlider GroupIndex={3} effect="blur"/>     
      </div>    
    )
}

export default Portraits;