export const ImagesData = [
    {
        group: 'Corporates',
        subGroup: [            
            {
                title: 'Hextar Global Berhad (Corporate Photo)',
                type: 'Photo',
                link : [
                    {url: "../images/WZSZW0UCJO.jpg",},
                    {url: "../images/MWRHTH9W0O.jpg",},
                    {url: "../images/MPVVFVMIGW.jpg",},
                    {url: "../images/L6GL4TVEZZ.jpg",},
                    {url: "../images/43NWU0WTM1.jpg",},
                    {url: "../images/RFUWTNRTVO.jpg",},
                    {url: "../images/YJZIEATVBE.jpg",},
                    {url: "../images/SRDDP0SDQH.jpg",},
                    {url: "../images/PRXCVY6QB5.jpg",},
                    {url: "../images/R7UNPLQCBW.jpg",},
                    {url: "../images/ECKOOHHDJO.jpg",},
                    {url: "../images/F81GXS3VGZ.jpg",},
                    {url: "../images/Y632RDIDOC.jpg",},
                    {url: "../images/ZB2944MYPQ.jpg",},
                    {url: "../images/AV5D0UGEJY.jpg",}
                ]
            },
            {
                title: 'Taliworks Corporation (LGB Group)',
                type: 'Video',        
                link : [ 
                    {url: "iS3GsScNrSI"}
                ]
            },         
            {
                title: 'Axiata Cup (Event)',
                type: 'Photo',
                link : [
                    {url: "../images/TPRKGJH6U5.jpg",},
                    {url: "../images/V3AR8QDYSB.jpg",}, 
                    {url: "../images/SMUDQJ5JBM.jpg",}, 
                    {url: "../images/L09EQDTHIS.jpg",}, 
                    {url: "../images/VGI4DYXQCQ.jpg",}, 
                    {url: "../images/OZVCFCPRTM.jpg",}, 
                    {url: "../images/2LV5RXSJY6.jpg",}, 
                    {url: "../images/GWKGS7RW4V.jpg",}, 
                    {url: "../images/TDZGT6BPRL.jpg",}, 
                    {url: "../images/KP2BJGBWIW.jpg",}, 
                    {url: "../images/JL8OC1VQPY.jpg",}, 
                    {url: "../images/B7J0SZARH5.jpg",}, 
                    {url: "../images/JMQ3EWPBQM.jpg",}, 
                    {url: "../images/KID79RKURM.jpg",}, 
                    {url: "../images/GHWJDP6NMC.jpg",}, 
                    {url: "../images/5YAS7VIYZE.jpg",}, 
                ]
            },   
        ]
    },
    {
        group: 'Products',        
        subGroup: [            
            {
                title: 'Mr Fish & Seafood Noodle',
                type: 'Photo',
                link : [
                    {url: "../images/JYPVQFMN59.jpg",},
                    {url: "../images/IZPIGDASBP.jpg",},                  
                    {url: "../images/C5I3JJWTNS.jpg",},                
                    {url: "../images/FDXPGO7SWT.jpg",},
                    {url: "../images/XX8X31N3TZ.jpg",},
                    {url: "../images/X2TC4VVPHB.jpg",},
                    {url: "../images/2F0STUDPX4.jpg",},
                    {url: "../images/7TOW16YQ7Y.jpg",},
                    {url: "../images/D4E5H2IZWF.jpg",},
                    {url: "../images/HQSK4DNQRX.jpg",},
                    {url: "../images/I9L0R7LA3H.jpg",},
                    {url: "../images/JNRFB6SIJO.jpg",},
                ]
            }, 
            {
                title: 'Restaurant Ulu Yam',
                type: 'Photo',
                link : [
                    {url: "../images/7FA7KISGKE.jpg",},
                    {url: "../images/FLUBUZAUNR.jpg",},                  
                    {url: "../images/WVY7XPQFGM.jpg",},                
                    {url: "../images/FBFQUMJFXS.jpg",},
                    {url: "../images/AYYJUKQM19.jpg",},
                    {url: "../images/IFI7VILR8B.jpg",},
                    {url: "../images/EDSHRGA6IS.jpg",},
                    {url: "../images/JKMDML1PGT.jpg",},
                ]
            },
            {
                title: 'Sisley Cosmetic',
                type: 'Photo',
                link : [
                    {url: "../images/YG50F4XZBV.jpg",},
                    {url: "../images/IE0QOBYU1X.jpg",},                  
                    {url: "../images/7PF190JZI3.jpg",},                
                    {url: "../images/5DNLVVPKDX.jpg",},
                    {url: "../images/CQN8KYNDCR.jpg",},
                    {url: "../images/JSMIENB6RW.jpg",},
                    {url: "../images/UHBRIPMTQG.jpg",},
                    {url: "../images/NDURI0MUN4.jpg",},
                    {url: "../images/4KE4LMVBTH.jpg",},
                    {url: "../images/A13WFKPV78.jpg",},   
                    {url: "../images/6DMSPQXYR1.jpg",},       
                    {url: "../images/XW9WPTJ0ZE.jpg",},           
                    {url: "../images/YCDUPM7VVF.jpg",},  
                    {url: "../images/NZA7O58GMZ.jpg",},  
                    {url: "../images/9GWBZU2G7S.jpg",},                            
                ]
            },                   
        ],
    },
    {
        group: 'Weddings',
        subGroup: [            
            {
                title: 'Marvin + Jovi Pre Wedding',
                type: 'Photo',
                link : [
                    {url: "../images/JIARYAGJZM.jpg",},
                    {url: "../images/K3B46OUARL.jpg",},  
                    {url: "../images/E1QCNJWZZT.jpg",},
                    {url: "../images/1FIPTABVTW.jpg",},        
                    {url: "../images/KPRASXXRWF.jpg",}, 
                    {url: "../images/QR9QNBFQDZ.jpg",},     
                    {url: "../images/XT83TBNT5J.jpg",},
                    {url: "../images/KCTLLXQSLX.jpg",},
                    {url: "../images/4VIR4O6YSK.jpg",},
                    {url: "../images/DQ06Y6JSNA.jpg",},
                    {url: "../images/5FA2CHFB81.jpg",},
                    {url: "../images/NTEJLQEW53.jpg",},
                    {url: "../images/T0ND0D40EY.jpg",},
                    {url: "../images/YCHFVO9UGY.jpg",},
                    {url: "../images/HPIZE8JFVK.jpg",},
                ]
            },
            {    
                title: 'Jason + Matthena Pre Wedding',
                type: 'Photo',
                link : [
                    {url: "../images/T3UKIXPDGF.jpg",}, 
                    {url: "../images/0ZWSVO3TJE.jpg",},   
                    {url: "../images/TNCHBXATSL.jpg",},   
                    {url: "../images/768XRU75NV.jpg",},     
                    {url: "../images/KMFR2BWGE4.jpg",},     
                    {url: "../images/HYRAAIRBA2.jpg",},    
                    {url: "../images/HYZQC5S519.jpg",},  
                    {url: "../images/MMRBOQGMCW.jpg",},  
                    {url: "../images/RSBORUZYA4.jpg",},  
                    {url: "../images/SJCLOP77IR.jpg",},   
                    {url: "../images/VFNCIV9N6Z.jpg",},       
                    {url: "../images/MB6TEAMSPF.jpg",},       
                    {url: "../images/55IMXVSSIG.jpg",},       
                    {url: "../images/P2S9T30U90.jpg",}, 
                    {url: "../images/IJWOBFOF8E.jpg",},                  
                ] 
            }
        ],
    },
    {
        group: 'Portraits',
        subGroup: [            
            {
                title: 'Shirley 77th Birthdays',
                type: 'Photo',
                link : [
                    {url: "../images/O9KHY8F8EX.jpg",},      
                    {url: "../images/T86IYD5MEJ.jpg",}, 
                    {url: "../images/1TZH7OU2AT.jpg",}, 
                    {url: "../images/NUTIB3TYUI.jpg",}, 
                    {url: "../images/EXKC39OZ1G.jpg",},             
                    {url: "../images/TDLCIJFCOG.jpg",},    
                    {url: "../images/P0V4T0SFWG.jpg",},    
                    {url: "../images/JMYLZFVVHL.jpg",},    
                    {url: "../images/HD2RIWFQU2.jpg",},      
                    {url: "../images/D5H4IOGHA9.jpg",},   
                    {url: "../images/FEPTR9ZJ4F.jpg",},  
                    {url: "../images/KPKFBWP4B1.jpg",},  
                    {url: "../images/QIO7OJAEUV.jpg",},
                    {url: "../images/HHMUUDL8Z2.jpg",},                      
                ]
            },
            {
                title: 'Jaden + Grace Family Portrait',
                type: 'Photo',
                link : [
                    {url: "../images/EJP9KFP3V5.jpg",},      
                    {url: "../images/GSCYFP6B5A.jpg",}, 
                    {url: "../images/NUD9FNJOGN.jpg",}, 
                    {url: "../images/HEYOSCXEGJ.jpg",}, 
                    {url: "../images/VQFPTUUEMG.jpg",},             
                    {url: "../images/UAAFWU8TA7.jpg",},    
                    {url: "../images/L7EMZJMZXW.jpg",},    
                    {url: "../images/SJWPJ6Q4CV.jpg",},    
                    {url: "../images/MFYLVBTXCH.jpg",},      
                    {url: "../images/UDAXXT9QT1.jpg",},   
                    {url: "../images/XI6O98ROHT.jpg",},  
                    {url: "../images/NK76VOVI2R.jpg",},  
                    {url: "../images/VWLETLH1AG.jpg",},                      
                ]
            }
        ],
    }
];