import React from "react";
import $ from 'jquery';
import ImagesSlider from '../components/ImagesSlider';

function Products (){
  return(
    <div className="content" onLoad={()=>{
      $("#iDBtnProducts")[0].style.textShadow = '5px 5px 5px #070707';
    }}>
    <div className="layoutBackground" />         
      <ImagesSlider GroupIndex={1} /> 
    </div>
  )
}

export default Products;
