import React from "react";
import ImgDefault from "../assets/default.JPG";

function Layout (){
    return(    
      <div className='layout'>      
        <img className='layoutImg' src={ImgDefault} alt="Gene Gallery"/>
      </div>
    )
}

export default Layout;