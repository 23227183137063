import React from "react";
import { AiOutlineMail } from 'react-icons/ai';

function Contact (){
    return(  
      <div className="content">
        <div className="layoutBackground" />   
        <div className="contactbar">
          <h4>Contact</h4>
          <h2>Eugene Ong</h2>
          <h2>Mobile : + (60) 16 3978898</h2>
          <a href="mailto:eugeneong16@gmail.com"><AiOutlineMail size='40px'/><h2>Email Us</h2></a>
          <br></br>
          <br></br>
          <h4>Services</h4>
          <br></br>
          <h2>Photography</h2>
          <h3>● Corporate Photo ● Products ● Family Portrait ● Wedding Day ● Pre Wedding ● Event ● Area View ● Timelapse</h3>
          <br></br>
          <h2>Videography</h2>
          <h3>● Project Progress ● Advertisement ● Products ● Life Broadcast ● Wedding Day ● Pre Wedding ● Event ● Area View</h3>
          <br></br>
          <h2>Other</h2>
          <h3>● Customized Photo Album Book ● Customized Photo Frame ● Professional Photo Printing ● Photo Editing</h3>   
        </div>
      </div>    
    )
}

export default Contact;