import React, {useState} from 'react';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import $ from 'jquery';

const leftNavArrowStyles = {
    color: "#fff",
    zIndex: "1",
    cursor : "pointer",    
    position: "absolute",
    transform: "translate(0, -50%)",
    top:"50%",
    left: "32px",
} 

const rightNavArrowStyles = {
    color: "#fff",
    zIndex: 1,
    cursor : "pointer",
    position: "absolute",
    transform: "translate(0, -50%)",
    top:"50%",
    right: "32px"    
};

const PhotoPanel =({GroupID, type, subGroupTotalArray, ImagesData}) => {
    const [curGroupID, setCurGroupID] = useState(GroupID); 
    const [currentIndex, setCurrentIndex] = useState(0);    
       
    
    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ?  subGroupTotalArray : currentIndex - 1;        
        $(".slideImage").fadeOut(800, function() {
            setCurrentIndex(newIndex);
            $(".slideImage").fadeIn(800, function() {
            });
        });        
    }

    const goToNext = () =>{
        const isLastSlide = currentIndex === subGroupTotalArray;             
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        $(".slideImage").fadeOut(800, function() {
            setCurrentIndex(newIndex);
            $(".slideImage").fadeIn(800, function() {
            });
        });
    }

    const goToSlide = (slideIndex) => {
        $(".slideImage").fadeOut(800, function() {
            setCurrentIndex(slideIndex);
            $(".slideImage").fadeIn(800, function() {
            });
        });        
    }

    const initializedPage = () =>{        
        $(".dotStyle").css('color', 'white');
        $(".dotStyle").css('font-size', '26px'); 
        $("#iDDotsBtn" + currentIndex).css('color', 'red');
        $("#iDDotsBtn" + currentIndex).css('font-size', '34px');        
    } 

    if (type==='Photo'){       
        if (curGroupID !== GroupID){  
            setCurrentIndex(0);          
            setCurGroupID(GroupID);                 
        }    
        return(
            <div className="imageContainer" onLoad={()=>{initializedPage()}}>
                <MdChevronLeft style={leftNavArrowStyles} onClick={goToPrevious} size={80} title="Previous"/>
                <LazyLoadImage className='slideImage' src={ImagesData.at(currentIndex).url} />
                <MdChevronRight style={rightNavArrowStyles} onClick={goToNext} size={80} title="Next"/>
                <div className='dotsStylesContainer'>
                    {ImagesData.map((slide, slideIndex) => (
                        <div className="dotStyle" key={slideIndex} id={"iDDotsBtn" + slideIndex} key={slideIndex} onClick={() => goToSlide(slideIndex)}>●</div>
                    ))}
                </div>
            </div> 
        )
    }
}

export default PhotoPanel;