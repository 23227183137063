import React from "react";

function Home (){
    return(
      <div className="content">               
        <div className="layoutBackground" />              
      </div>
    )
}

export default Home;