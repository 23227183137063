import './App.css';
import Header from "./components/Header";
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './Pages/Home';
import Corporates from './Pages/Corporates';
import Products from './Pages/Products';
import Weddings from './Pages/Weddings';
import Portraits from './Pages/Portraits';
import Contact from './Pages/Contact';
import Layout from './Pages/Layout';
import PageNotFound from './Pages/PageNoFound';

function App(){ 
  return (    
      <BrowserRouter>
        <Layout/>    
        <Header />        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/Corporates' element={<Corporates />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/Weddings' element={<Weddings />} />
          <Route path='/Portraits' element={<Portraits />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>        
        <Footer />        
      </BrowserRouter>
  );
}

export default App;
