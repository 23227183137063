import React from 'react';
import $ from 'jquery';
import { Link } from "react-router-dom";
import ImgDefault from "../assets/default.JPG";
import ImgCorporate from "../assets/Corporate.jpg";
import ImgProduct from "../assets/Product.jpg";
import ImgWedding from "../assets/Wedding.jpg";
import ImgPortrait from "../assets/Portrait.jpg";

const ItemLink = ({name, linkRef, NewImage}) => {
    return (
        <li><Link to={linkRef} className="menuBtn"
        id={"iDBtn" + name}
        onClick={() => {
            $(".menuBtn").css("textShadow","none");
        }} 
        onMouseOver={() => {          
            $(".layout").fadeOut(500, function() {
                $('.layoutImg')[0].src = NewImage;
                $(".layout").fadeIn(800, function() {
                });
            });
        }} 
        onMouseOut={() => {
            $(".layout").fadeOut(500, function() {
                $('.layoutImg')[0].src = ImgDefault;
                $(".layout").fadeIn(800, function() {
                });
            });            
        }}>
        {name}</Link></li>       
    )
}

export default function NavBar(){
    return <nav className="nav" id="NavList">            
        <ul id="menuList">
            <ItemLink name='Corporates' linkRef='/Corporates' NewImage={ImgCorporate} />
            <ItemLink name='Products' linkRef='/Products' NewImage={ImgProduct} />
            <ItemLink name='Weddings' linkRef='/Weddings' NewImage={ImgWedding} />
            <ItemLink name='Portraits' linkRef='/Portraits' NewImage={ImgPortrait} />
        </ul>
    </nav>    
}